import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import SubpageHero from "../components/subpage-hero"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />

    <SubpageHero
       title="404: Not found"
    />

    <div className="post-content">
      <p>You just hit a route that doesn&#39;t exist.</p>
      <p>Back to the <Link to="/hand-sanitizer">home page</Link>.</p>
    </div>

  </Layout>
)

export default NotFoundPage
