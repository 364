import React from 'react'
import './subpage-hero.css'

const SubpageHero = props => (
    
    <div className="subpage-hero">
        <div className="subpage-hero-group">
            <h1>{props.title}</h1>
        </div>
    </div>
)


export default SubpageHero